// React & Gatsby
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Custom
import Page from "../components/Layout/Page"
import Blog from "../components/Content/Blog";
import Hero from "../components/Hero";
import Story from "../components/Content/Story";

// Data & Styles
import "../assets/scss/App.scss"


class BlogPage extends Component {
    render() {
        const heading = <h1>Vector Icons Blog</h1>;
        const subheading = <h2>Articles about designing, buying, and selling vector icons</h2>;

        return (
            <Page
                className={'blog'}
                hero={
                    <Hero
                        heading={heading}
                        subheading={subheading}
                        bgImage={false}
                    />
                }
            >
                <Container className={'mt-4 mb-4'}>
                    <Row role={'main'} className={'justify-content-center mt-md-5 pt-md-5'}>
                        <Col sm={12} lg={8}>
                            <Blog/>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Row>
                                <Col>
                                    <Story slug={'other-sites'}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Page>
        )
    }
}

export default BlogPage
