import React from "react"
import { Row, Col } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby"


const Blog = ((props) => {
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/blog\/"}}) {
                    edges {
                        node {
                            id
                            frontmatter {
                                slug
                                title
                                subtitle
                                alt
                                date
                                image
                            }
                            html
                            rawMarkdownBody
                            excerpt
                        }
                    }
                }
            }
        `
    )

    return (
        allMarkdownRemark.edges.map((data) => {
            const post = data.node;
            const { frontmatter } = post;
            return(
                <Row>
                    <Col>
                        <div key={frontmatter.slug}>
                            <h2 className={'mb-4'}>
                                <Link to={`/blog/${frontmatter.slug}`}>{frontmatter.title}</Link>
                            </h2>
                            <p>{post.excerpt}</p>
                            <p><a href={`/blog/${frontmatter.slug}`}>Continue reading →</a></p>
                        </div>
                    </Col>
                </Row>
            )
        })
    )
})

export default Blog;
